<template>
  <div class="final-g11-index">
    <my-search-bar>
      <el-form>
        <el-button v-if="showAddBtn" @click="handleAdd">添加</el-button>
        <el-button v-if="showAddBtn" @click="deleteAll">删除所有</el-button>
      </el-form>
    </my-search-bar>
    <br>
    <my-el-table
      v-if="finalG11List.length"
      :data="finalG11List"
      :total="total"
      :page-index="pageIndex"
      :page-size="pageSize"
      keyFiled="finalG11Guid"
      @size-change="handlePageSizeChange"
      @current-change="handlePageIndexChange"
    >
      <el-table-column fixed="left" prop="index" width="50" header-align="center" align="center"
                       label="序号"></el-table-column>
      <el-table-column fixed="left" prop="meterageList.meterageDisplayCode" width="130" header-align="left" align="left"
                       label="清单项目编号"></el-table-column>
      <el-table-column fixed="left" prop="meterageList.meterageName" header-align="left" align="left"
                       label="工程或费用项目名称"></el-table-column>
      <el-table-column fixed="left" prop="meterageList.unit" width="60" header-align="center" align="center"
                       label="单位"></el-table-column>
      <el-table-column fixed="left" prop="meterageList.price" width="100" header-align="center" align="center"
                       label="单价"></el-table-column>
      <el-table-column label="合同数量" header-align="center">
        <el-table-column prop="meterageList.num" width="120" header-align="center" align="center"
                         label="原合同数量"></el-table-column>
        <el-table-column prop="meterageList.num" width="120" header-align="center" align="center"
                         label="施工图数量"></el-table-column>
        <el-table-column prop="" width="120" header-align="center" align="center" label="变更数量"></el-table-column>
        <el-table-column prop="meterageList.num" width="120" header-align="center" align="center"
                         label="变更后数量"></el-table-column>
        <el-table-column prop="meterageList.amount" width="120" header-align="center" align="center"
                         label="工作量（元）"></el-table-column>
      </el-table-column>
      <el-table-column label="年度计划" header-align="center">
        <el-table-column prop="currentYearNum" width="120" header-align="center" align="center"
                         label="工程量"></el-table-column>
        <el-table-column prop="currentYearAmount" width="120" header-align="center" align="center"
                         label="工作量（元）"></el-table-column>
        <el-table-column prop="currentYearPercent" width="120" header-align="center" align="center"
                         label="占合同量%"></el-table-column>
      </el-table-column>
      <el-table-column label="本月计划" header-align="center">
        <el-table-column
          width="80"
          align="center"
          prop="currentMonthNum"
          label="工程量">
        </el-table-column>
        <el-table-column prop="currentMonthAmount" width="120" header-align="center" align="center"
                         label="工作量（元）"></el-table-column>
        <el-table-column prop="currentMonthPercent" width="120" header-align="center" align="center"
                         label="占年工程量%"></el-table-column>
      </el-table-column>
      <el-table-column label="本月计划年度累计" header-align="center">
        <el-table-column prop="totalMonthNum" width="120" header-align="center" align="center"
                         label="工程量"></el-table-column>
        <el-table-column prop="totalMonthAmount" width="120" header-align="center" align="center"
                         label="工作量（元）"></el-table-column>
        <el-table-column prop="totalMonthPercent" width="120" header-align="center" align="center"
                         label="占年工程量%"></el-table-column>
      </el-table-column>
      <el-table-column v-if="showAddBtn" fixed="right" width="50" label="操作" header-align="center" align="center">
        <template v-slot="scope">
          <el-tooltip class="item" effect="light" content="删除" :open-delay="500" placement="top">
            <i @click="handleDeleteClick(scope.row)" class="text-danger far fa-trash-alt operation-button"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </my-el-table>
  </div>
</template>

<script>
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import axios from 'axios'
import config from '@/config'
import auth from '@/common/auth'
import { mapGetters } from 'vuex'
import MyElTable from '@/components/MyElTable/MyElTable'
import PageOperation from '@/mixins/PageOperation'

export default {
  name: 'FinalG11Index',
  mixins: [
    PageOperation
  ],
  components: {
    MySearchBar,
    MyElTable
  },
  data () {
    return {
      finalG11List: [],
      total: 0,
      monthNames: new Array(12).fill(0).map((_, index) => `${index + 1}月`)
    }
  },
  methods: {
    handleAdd () {
      this.$router.push('/finalG11')
    },
    async handleDeleteClick (row) {
      const response = await axios.delete(`${config.restHost}/finalG11/${row.finalG11Guid}`, {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`
        }
      })
      console.log(response.data)
      this.$message.success('删除成功')
      await this.init()
    },
    async deleteAll () {
      const response = await axios.delete(`${config.restHost}/finalG11/${this.tenderGuid}/${this.year}/${this.month}/deleteAll`, {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`
        }
      })
      console.log(response.data)
      this.$message.success('删除成功')
      await this.init()
    },
    async init () {
      this.$loading({})
      const response = await axios.get(`${config.restHost}/finalG11/${this.tenderGuid}`, {
        params: {
          pageIndex: this.pageIndex,
          pageSize: this.pageSize,
          year: this.year,
          month: this.month
        },
        headers: {
          Authorization: `Bearer ${auth.getToken()}`
        }
      })
      console.log(response.data)
      this.total = response.data.total
      this.finalG11List = response.data.list
      console.log(this.finalG11List, 'final g11 list')
      this.$loading({}).close()
    }
  },
  computed: {
    showAddBtn () {
      return this.approvalUserGuid === auth.getUserInfo().userGuid
    },
    ...mapGetters('planStatExamination', {
      planStatExaminationGuid: 'getPlanStatExaminationGuid',
      tenderGuid: 'getTenderGuid',
      tenderName: 'getTenderName',
      approvalUserGuid: 'getApprovalUserGuid',
      year: 'getYear',
      month: 'getMonth'
    })
  },
  async mounted () {
    await this.init()
    this.refreshMethod = this.init
  }
}
</script>

<style scoped lang="scss">
::v-deep ::-webkit-scrollbar {
  display: block;
}
::v-deep ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::v-deep ::-webkit-scrollbar-track {
  border-radius: 15px;
  width: 10px;
}
::v-deep ::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #bbb;
  box-shadow: inset 0 0 6px rgba(200, 200, 200, 0.3);
}
::v-deep ::-webkit-scrollbar-thumb:window-inactive {
  background: #eee;
}
</style>
